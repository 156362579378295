<template>
<!--  <Spinner v-if="user && isLoading"></Spinner>-->
<!--  <div class="p-mt-4 p-d-flex p-jc-between" v-if="!isLoading && !$store.state.tabletLayout">-->
  <div class="p-mt-4 p-d-flex p-jc-between" v-if="user">
    <div>
      <span class="p-mr-3">Default percent:</span>
      <InputNumber :locale="computedNumberInputLocale" inputStyle="width: 120px" showButtons @input="isDataChanged = true" id="default_percent" class="p-mr-2" v-model="salarySettings.default_percent" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" autocomplete="off"/>
      <Button :disabled="!isDataChanged" @click="saveSalarySettings" class="p-button-raised p-mr-2" icon="ti-save" :label="$t('Save')"></Button>
      <Button @click="confirmClearSettings" class="p-button-warning p-button-raised" icon="ti-reload" label="Clear all settings"></Button>
    </div>
    <SearchInput :disabled="dataTableIsLoading"  @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
  </div>

<!--  <DesktopDataTable v-if="!isLoading && !$store.state.tabletLayout"-->
  <Spinner v-if="isLoading"></Spinner>
  <DesktopDataTable v-else
                    class="p-mt-4"
                    tableName="Services"
                    :dataTableIsLoading="dataTableIsLoading"
                    :itemsArrayIsEmpty="itemsArrayIsEmpty"
                    :sort="sort"
                    :showSearchInput="false"
                    :showButtons="false"
                    @sort-items="sortItems"
                    :headers="[
              // {name: 'code', title: 'Code', width: '12%', sortable: true},
              {name: 'name', title: 'Service name', width: '30%', sortable: true},
              {name: 'time', title: 'Time', sortable: true},
              {name: 'sell', title: 'Sell', sortable: true},
              {name: 'percent', title: 'Percent', width: '200px', sortable: false},
              {name: 'value', title: 'Value', width: '200px', sortable: false},
          ]">
    <template v-slot:body>
      <template  v-for="(item, index) of items" :key="index">
        <tr :class="{'updated-item': isItemUpdated(item.id)}">
<!--          <td>{{ item.code }}</td>-->
          <td>{{ item.name }}</td>
          <td>
            <span v-if="item.time || item.time === 0">{{ formatDecimal(item.time) + ' ' + $t('h') + '.' }}</span>
            <i v-else class="ti-minus"></i>
          </td>
          <td>
            <span v-if="item.sell || item.sell === 0">{{ formatCurrency(+item.sell) }}</span>
            <i v-else class="ti-minus"></i>
          </td>
          <td>
<!--            <InputNumber :locale="computedNumberInputLocale" @input="changePercent(item, $event.value)" mode="decimal" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" v-model="item.percent" autocomplete="off"/>-->
            <InputNumber :locale="computedNumberInputLocale" inputStyle="width: 120px" showButtons @input="changePercent(item, $event.value)" mode="decimal" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" v-model="item.percent" autocomplete="off"/>
          </td>
          <td>
            <InputNumber :locale="computedNumberInputLocale" inputStyle="width: 120px" showButtons @input="changeValue(item, $event.value)" mode="decimal" :min="0" :max="+item.sell.toFixed(2)" :minFractionDigits="2" :maxFractionDigits="2" v-model="item.value" autocomplete="off"/>
          </td>

<!--          <td>-->
<!--            <div class="table__actions-column-data">-->
<!--              <router-link :to="{ path: `/cars/${item.id}` }" target="_blank"><ViewButton/></router-link>-->
<!--              <EditButton v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant  || $store.state.user.role === constants.userRoles.moderator"-->
<!--                          @click="editItem(item)" />-->
<!--            </div>-->
<!--          </td>-->
        </tr>
      </template>
    </template>
  </DesktopDataTable>

  <OwnPaginator v-show="items && items.length"
                :rows="pagination.perPage"
                :currentPage="pagination.currentPage - 1"
                :totalPages="pagination.pageCount"
                :totalRecords="pagination.totalCount"
                :rowsPerPageOptions="[5,10,25,50,100,200]"
                :pageLinkSize="11">
  </OwnPaginator>

  <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

  <ConfirmModal :visible="confirmModal"
                @confirm="clearSettings"
                @close="closeConfirmModal"/>

</template>

<script>
import httpClient from '@/services/http.services'
import ConfirmModal from '@/components/ConfirmModal'
import httpMixins from "@/mixins/httpMixins";
import datatableMixins from "@/mixins/datatableMixins";
import formatMixins from "@/mixins/formatMixins";
import settings from "@/settings";

export default {
  mixins: [ httpMixins, datatableMixins, formatMixins ],
  components: { ConfirmModal },
  emits: ['update-items', 'updateItems'],
  props: {
    user: Object,
  },
	data() {
		return {
      confirmModal: false,
      isDataChanged: false,
      activeIndex: '',
      salarySettings: {
        default_percent: null,
        services: []
      },
      fieldsExpand: '',
      apiLink: 'service/get-active-services',
      sort: {
        column: 'id',
        direction: '-',
      },
		}
	},
  watch: {
    '$route'() {
      const route = this.$route.path.split('/')
      if (this.$route.path === '/login' || route.indexOf('employees') === -1 || route.indexOf('salary-settings') === -1) {
        return false
      }
      this.checkRouteQuery()
      this.getItems()
    },
    // user() {
    //   this.loadData()
    // }
  },
  mounted() {
    // if (this.$store.state.user?.role || !this.$route.params.id) return false
    if (!this.$route.params.id) return false
    this.loadData()
    if (this.user?.employee?.salary_settings) {
      this.salarySettings = JSON.parse(this.user.employee.salary_settings)
    }
  },
  methods: {
    confirmClearSettings() {
      this.confirmModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeConfirmModal() {
      this.confirmModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    async saveSalarySettings() {
      const obj = {
        employeeId: this.user?.employee?.id,
        settings: JSON.stringify(this.salarySettings)
      }
      try {
        const { status } = await httpClient.post('employee/save-salary-settings', obj)
        if (status === 200) {
          this.$emit('update-items')
          this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: settings.toastLife});
          this.getItems()
          this.isDataChanged = false
        }
      } catch(err) {
        this.showError(err)
      }
    },
    clearSettings() {
      this.salarySettings.services = []
      this.closeConfirmModal()
      this.saveSalarySettings()
    },
    changePercent(item, value) {
      this.isDataChanged = true
      const itemSellPrice = +item.sell.toFixed(2)
      // if (value) {
        if (value > 100) {
          item.percent = 100
          item.value = itemSellPrice * (item.percent / 100)
        } else {
          item.percent = +value
          item.value = itemSellPrice * (item.percent / 100)
        }
        const service = this.salarySettings.services?.length  ? this.salarySettings.services.find(service => service.id === item.id) : null
        if (!service) {
          item = { id: item.id, percent: item.percent.toFixed(2) }
          this.salarySettings.services.push(item)
        } else {
          service.percent = item.percent.toFixed(2)
        }
      // }
    },
    changeValue(item, value) {
      this.isDataChanged = true
      const itemSellPrice = +item.sell.toFixed(2)
      // if (value) {
        if (value > itemSellPrice) {
          item.value = itemSellPrice
          item.percent = 100
        } else {
          item.value = value
          item.percent = (item.value * 100) / itemSellPrice
        }
        const service = this.salarySettings.services?.length  ? this.salarySettings.services.find(service => service.id === item.id) : null
        if (!service) {
          item = { id: item.id, percent: item.percent.toFixed(2) }
          this.salarySettings.services.push(item)
        } else {
          service.percent = item.percent.toFixed(2)
        }
      // }
    },
    loadData() {
      this.checkRouteQuery()
      this.getItems()
    },
    // updateItems(car, clearItem = false) {
    //   if (car) {
    //     this.updatedItemId = car.id
    //   }
    //   if (clearItem) {
    //     this.item = {}
    //     this.submitted = false
    //   }
    //   this.$emit('update-services')
    //   this.getItems(false)
    // },
    // closeItemModal() {
    //   // this.item = {}
    //   this.itemModal = false
    //   this.submitted = false
    //   if (this.$store.state.firstLayerIsOpened === true) {
    //     this.$store.commit('toggleFirstLayer', false)
    //   }
    // },
    // addNewItem() {
    //   this.item = {}
    //   this.submitted = false
    //   this.itemModal = true
    //   this.$store.commit('toggleFirstLayer', true)
    // },
    async getItems() {
      this.dataTableIsLoading = true
      this.disableEditButton = true
      try {
        // const { status, data, headers } = await httpClient.post(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`, { id: this.$route.params.id })
        const { status, data, headers } = await httpClient({
          // const { data } = await httpClient({
          url: this.apiLink,
          method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          data: { id: this.$route.params.id }
        })
        if (status === 200) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data.map(service => {
            const foundService = this.salarySettings.services?.find(serv => serv.id === service.id)
            const computedPercent = foundService ? +foundService.percent : ( this.salarySettings.default_percent || 0)
            const computedValue = +service.sell * (computedPercent / 100)
            return {
              id: service.id,
              code: service.code,
              name: service.name,
              time: service.time,
              sell: +service.sell,
              percent: computedPercent,
              value: computedValue,
            }
          })

          if (!this.items.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
      }
    },
	},
  computed: {
    itemToDeleteData() {
      return 'delete data'
    },
    itemsArrayIsEmpty() {
      return !(this.items && this.items.length)
    },
    // actionButtonsCount() {
    //   if (this.$store.state.user.role === this.constants.userRoles.superAdmin || this.$store.state.user.role === this.constants.userRoles.admin || this.$store.state.user.role === this.constants.userRoles.accountant  || this.$store.state.user.role === this.constants.userRoles.moderator) {
    //     return 2
    //   } else {
    //     return 1
    //   }
    // },
  }
}
</script>

<!--<style scoped lang="scss">-->
<!--.datatable-wrapper {-->
<!--  overflow-y: auto;-->
<!--  padding-bottom: 25px;-->
<!--}-->

<!--.positions-table {-->
<!--  border-collapse: collapse;-->
<!--  width: 100%;-->
<!--  tr {-->
<!--    td {-->
<!--      padding: 8px !important;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->
<style scoped lang="scss">
.datatable:not(:first-child) {
  margin-bottom: 45px;
}

.opened-order-data {
  font-size: 0.92em;
}

.table-opened-info__header {
  padding: 8px 10px;
}

.table-opened-info__data {
  padding: 8px !important;
}

.table-opened-info__data--no-border {
  border-bottom: none !important;
}

.order-name-link {
  padding: 2px 3px;
  margin-left: -4px;
}

.order-name-title {
  padding: 6px 4px;
}
.order-name__icon {
  padding: 4px 6px;
  font-size: 0.95em;
  &:nth-child(2) {
    //margin-left: 3px;
  }
  &:hover {
    opacity: 0.70;
  }
}
</style>